import { createRouter, createWebHistory } from 'vue-router';

const FrontLayout = () => import('../frontoffice/layout/FrontLayout.vue');
const HomeView = () => import('../frontoffice/pages/HomeView.vue');
const HotelsView = () => import('../frontoffice/pages/HotelsView.vue');
const DetailsStateView = () => import('../frontoffice/pages/DetailsStateView.vue');
const DetailsHotelsView = () => import('../frontoffice/pages/DetailsHotelsView.vue');
const DetailsRestaurantsView = () => import('../frontoffice/pages/DetailsRestaurantsView.vue');
const DetailsEventsView = () => import('../frontoffice/pages/DetailsEventsView.vue');
const DetailsMusseView = () => import('../frontoffice/pages/DetailsMusseView.vue');
const RestaurantsView = () => import('../frontoffice/pages/RestaurantsView.vue');
const EventsView = () => import('../frontoffice/pages/EventsView.vue');
const MusseView = () => import('../frontoffice/pages/MusseView.vue');
const CarView = () => import('../frontoffice/pages/CarView.vue');
const WeekendView = () => import('../frontoffice/pages/WeekendView.vue');
const ActivityView = () => import('../frontoffice/pages/ActivityView.vue');
const ActivityDetailsView = () => import('../frontoffice/pages/ActivityDetailsView.vue');
const VolsView = () => import('../frontoffice/pages/VolsView.vue');

const MaisonView = () => import('../frontoffice/pages/MaisonView.vue');
const MaisonDetailsView = () => import('../frontoffice/pages/MaisonDetailsView.vue');

const AproposView = () => import('../frontoffice/pages/AproposView.vue');
const ConditionsView = () => import('../frontoffice/pages/ConditionsView.vue');
const TermesView = () => import('../frontoffice/pages/TermesView.vue');
const MissionView = () => import('../frontoffice/pages/MissionView.vue');
const ContactView = () => import('../frontoffice/pages/ContactView.vue');
const AnnuaireHotelView = () => import('../frontoffice/pages/AnnuaireHotelView.vue');
const AnnuaireRestaurantView = () => import('../frontoffice/pages/AnnuaireRestaurantView.vue');
const ConfirmationEventView = () => import('../frontoffice/pages/ConfirmationEventView.vue');
const ReservationEventView = () => import('../frontoffice/pages/ReservationEventView.vue');
const ReservationHotelView = () => import('../frontoffice/pages/ReservationHotelView.vue');
const ReservationRestaurantView = () => import('../frontoffice/pages/ReservationRestaurantView.vue');
const AgilView = () => import('../frontoffice/pages/AgilView.vue');
const GatView = () => import('../frontoffice/pages/GatView.vue');
const ComposeCircuitView = () => import('../frontoffice/pages/ComposeCircuitView.vue');
const CircuitReservationView = () => import('../frontoffice/pages/CircuitReservationView.vue');
const SearchPageView = () => import('../frontoffice/pages/SearchPageView.vue');

const routes = [
  {
    path: '/',
    component: FrontLayout,
    children: [
      { path: '', component: HomeView },
      { path: 'search', 
        component: SearchPageView,
        name: 'search', 
      props: (route) => ({ query: route.query.q })
      },
      { path: 'apropos', component: AproposView },
      { path: 'confidentialite', component: ConditionsView },
      { path: 'confirmation', component: ConfirmationEventView },
      { path: 'termes', component: TermesView },
      { path: 'mission', component: MissionView },
      { path: 'hotels', component: HotelsView },
      { path: 'hotels/annuaire', component: AnnuaireHotelView },
      { path: 'restaurants', component: RestaurantsView },
      { path: 'restaurants/reservation', component: ReservationRestaurantView },
      { path: 'restaurants/annuaire', component: AnnuaireRestaurantView },
      { path: 'events', component: EventsView },
      { path: 'events/reservation', component: ReservationEventView },
      { path: 'hotels/reservation', component: ReservationHotelView },
      { path: 'maison', component: MaisonView },
      { path: 'vols', component: VolsView },

      { path: 'maison/:id', 
        component: MaisonDetailsView,        
        name: 'MaisonDetailsView',
        props: true, },
      { path: 'musses', component: MusseView },
      { path: 'weekend', component: WeekendView },
      { path: 'contact', component: ContactView },
      { path: 'activity', component: ActivityView },
      { path: 'activity/:id', 
        component: ActivityDetailsView,
        name: 'ActivityDetailsView',
        props: true,
       },

      { path: 'cars', component: CarView },
      { path: 'circuits/compose', component: ComposeCircuitView },
      { path: 'circuits/reservation', component: CircuitReservationView },
      { path: 'agil', component: AgilView },
      { path: 'gat', component: GatView },

      {
        path: 'destination/:id/:type/:city',
        component: DetailsStateView,
        name: 'DetailsStateView',
        props: true,
      },
      {
        path: 'hotels/:id',
        component: DetailsHotelsView,
        name: 'DetailsHotelsView',
        props: true,
      },
      {
        path: 'restaurants/:id',
        component: DetailsRestaurantsView,
        name: 'DetailsRestaurantsView',
        props: true,
      },
      {
        path: 'events/:id',
        component: DetailsEventsView,
        name: 'DetailsEventsView',
        props: true,
      },
      {
        path: 'musse/:id',
        component: DetailsMusseView,
        name: 'DetailsMusseView',
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top
    return { top: 0 };
  },
});

export default router;
