import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        banners:[],
        weekend:[],
        city:[],
        cars:[],
        agil:[],
        airports:[],
        states:[],
        destinations:[],
        musses:[],
        hotels:[],
        maison:[],
        restaurants:[],
        specialHotels:[],
        specialRestaurants:[],
        specialMaison:[],
        activities:[],
        events:[],
        event:{},
        musse:{},
        hotelDestination:[],
        hotelsByPage:{},
        restaurantByPage:{},
        eventsByPage:{},
        musseByPage:{},
        weekendByPage:{},
        activityByPage:{},
        maisonByPage:{},
        carByPage:{},
        roomSelected:[],
        hotelSelected:{},
        restaurantDestination:[],
        activitiesDestination:[],
        articles:[],
        gat:[],
        token:"",
        user:{},
        reservationSelected:{},
        restaurantSelected:{},
        circuitSelected:{}
    },
    mutations: {
        SET_SELECTED_CIRCUIT(state,circuitSelected){
            state.circuitSelected=circuitSelected;
        },
        SET_SELECTED_RESERVATION(state,reservationSelected){
            state.reservationSelected=reservationSelected;
        },
        SET_AGIL(state,agil){
            state.agil=agil;
        },
        SET_GAT(state,gat){
            state.gat=gat;
        },
        SET_SELECTED_RESTAURANT(state,restaurantSelected){
            state.restaurantSelected=restaurantSelected;
        },
        SET_SELECTED_ROOM(state,roomSelected){
            state.roomSelected=roomSelected;
        },
        SET_SELECTED_HOTEL(state,hotelSelected){
           state.hotelSelected=hotelSelected;
        },
        SET_EVENT(state, event) {
            state.event = event;
        },
        SET_MUSSE_BY_PAGE(state, maisonByPage){
           state.maisonByPage=maisonByPage
        },
        SET_MAISON_BY_PAGE(state, musse){
            state.musseByPage=musse
         },
        SET_WEEKEND_BY_PAGE(state, weekend){
            state.weekendByPage=weekend
         },
        SET_ACTIVITY_BY_PAGE(state, activity){
            state.activityByPage=activity
        },
        SET_CAR_BY_PAGE(state, car){
            state.carByPage=car
        },
        SET_EVENTS_BY_PAGE(state, eventsByPage) {
            state.eventsByPage = eventsByPage;
        },
        SET_HOTELS_BY_PAGE(state, hotelsByPage) {
            state.hotelsByPage = hotelsByPage;
        },
        SET_RESTAURANT_BY_PAGE(state, restaurantsByPage) {
            state.restaurantsByPage = restaurantsByPage;
        },
        SET_MUSSE(state, musse) {
            state.musse = musse;
        },
        SET_ARTICLES(state, articles) {
            state.articles = articles;
        },
        SET_CARS(state, cars) {
            state.cars = cars;
        },
        SET_HOTELS_DESTINATIONS(state, hotelsDestination) {
            state.hotelsDestination = hotelsDestination;
        },
        SET_ACTIVITIES_DESTINATIONS(state, activitiesDestination) {
            state.activitiesDestination = activitiesDestination;
        },
        SET_RESTAURANTS_DESTINATIONS(state, restaurantDestination) {
            state.restaurantDestination = restaurantDestination;
          },
        SET_EVENTS(state, events) {
            state.events = events;
        },
        SET_MUSSES(state, musses) {
            state.musses = musses;
        },
        SET_ACTIVITY(state, activities) {
            state.activities = activities;
        },
        SET_CITY(state, city) {
            state.city = city;
        },
        SET_STATES(state, states) {
            state.states = states;
        },
        SET_AIRPORTS(state, airports) {
            state.airports = airports;
        },
        SET_WEEKEND(state, weekend) {
            state.weekend = weekend;
        },
        SET_BANNERS(state, banners) {
            state.banners = banners;
        },
        SET_DESTINATIONS(state, destinations) {
            state.destinations = destinations;
        },
        SET_HOTELS(state, hotels) {
            state.hotels = hotels;
        },
        SET_RESTAURANTS(state, restaurants) {
            state.restaurants = restaurants;
        },
        SET_MAISON(state, maisons) {
            state.maisons = maisons;
        },
        SET_SPECIAL_MAISON(state, specialMaisons) {
            state.specialMaisons = specialMaisons;
        },
        SET_SPECIAL_HOTELS(state, specialMaisons) {
            state.specialMaisons = specialMaisons;
        },
        SET_SPECIAL_RESTAURANTS(state, specialRestaurants) {
            state.specialRestaurants = specialRestaurants;
        },
    },
    actions: {
        async fetchBanners({ commit, state }) {  
            if (state.banners.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}a_d_s_f_ront`);
                    const banners = response.data.states;
                    commit('SET_BANNERS', banners);
                    return banners;
                } catch (error) {
                    console.error('Error fetching Banners:', error);
                }
            }
            else{
                return state.banners;
            }
        },
        async fetchDestinations({ commit, state }) {  
            if (state.destinations.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}alldestinations`);
                    const destinations = response.data.destinations;
                    commit('SET_DESTINATIONS', destinations);
                    return destinations;
                } catch (error) {
                    console.error('Error fetching destinations:', error);
                }
            }
            else{
                return state.destinations;
            }
        },
        async fetchHotels({ commit, state }) {  
            if (state.hotels.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allhotels`);
                    const hotels = response.data.hotels;
                    commit('SET_HOTELS', hotels);
                    return hotels;
                } catch (error) {
                    console.error('Error fetching hotels:', error);
                }
            }
            else{
                return state.hotels;
            }
        },
        async fetchMaison({ commit, state }) {  
            if (state.maison.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allmaison`);
                    const maisons = response.data.maisons;
                    commit('SET_MAISON', maisons);
                    return maisons;
                } catch (error) {
                    console.error('Error fetching maisons:', error);
                }
            }
            else{
                return state.maisons;
            }
        },
        async fetchRestaurants({ commit, state }) {  
            if (state.restaurants.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allrestaurants`);
                    const restaurants = response.data.restaurants;
                    commit('SET_RESTAURANTS', restaurants);
                    return restaurants;
                } catch (error) {
                    console.error('Error fetching restaurants:', error);
                }
            }
            else{
                return state.restaurants;
            }
        },
        async fetchSpecialHotels({ commit, state }) {  
            if (state.specialHotels.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}hotelsspecial`);
                    const hotelsspecial = response.data.hotels.data;
                    commit('SET_SPECIAL_HOTELS', hotelsspecial);
                    return hotelsspecial;
                } catch (error) {
                    console.error('Error fetching hotelsspecial:', error);
                }
            }
            else{
                return state.hotelsspecial;
            }
        },
        async fetchSpecialRestaurants({ commit, state }) {  
            if (state.specialRestaurants.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurantsspecial`);
                    const restaurantsspecial = response.data.restaurants.data;
                    commit('SET_SPECIAL_RESTAURANTS', restaurantsspecial);
                    return restaurantsspecial;
                } catch (error) {
                    console.error('Error fetching restaurantsspecial:', error);
                }
            }
            else{
                return state.specialRestaurants;
            }
        },
        async fetchSpecialMaisons({ commit, state }) {  
            if (state.specialMaison.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}maisonspecial`);
                    const maisonspecial = response.data.maisons;
                    commit('SET_SPECIAL_MAISON', maisonspecial);
                    return maisonspecial;
                } catch (error) {
                    console.error('Error fetching maisonspecial:', error);
                }
            }
            else{
                return state.specialMaison;
            }
        },
        async fetchCars({ commit, state }) {  
            if (state.cars.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allcars`);
                    const cars = response.data.cars.data;
                    commit('SET_CARS', cars);
                    return cars;
                } catch (error) {
                    console.error('Error fetching cars:', error);
                }
            }
            else{
                return state.cars;
            }
        },
        async fetchAirports({ commit, state }) {  
            if (state.airports.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}airport`);
                    const airports = response.data.airports.data;
                    commit('SET_AIRPORTS', airports);
                    return airports;
                } catch (error) {
                    console.error('Error fetching airports:', error);
                }
            }
            else{
                return state.airport;
            }
        },
        async fetchWeekend({ commit, state }) {  
            if (state.weekend.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}weekend`);
                    const weekend = response.data.events.data;
                    commit('SET_WEEKEND', weekend);
                    return weekend;
                } catch (error) {
                    console.error('Error fetching weekend:', error);
                }
            }
            else{
                return state.weekend;
            }
        },
        async fetchStates({ commit, state }) {  
            if (state.states.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}states`);
                    const states = response.data.states;
                    commit('SET_STATES', states);
                    return states;
                } catch (error) {
                    console.error('Error fetching states:', error);
                }
            }
            else{
                return state.states;
            }
        },
        async fetchAgil({ commit, state }) {  
            if (state.agil.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}agil`);
                    const agil = response.data.agil;
                    commit('SET_AGIL', agil);
                    return agil;
                } catch (error) {
                    console.error('Error fetching agil:', error);
                }
            }
            else{
                return state.agil;
            }
        },
        async fetchGat({ commit, state }) {  
            if (state.gat.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}gat`);
                    const gat = response.data.gat;
                    commit('SET_GAT', gat);
                    return gat;
                } catch (error) {
                    console.error('Error fetching gat:', error);
                }
            }
            else{
                return state.agil;
            }
        },
        async fetchCity({ commit, state }) {  
            if (state.city.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}city`);
                    const city = response.data.city;
                    commit('SET_CITY', city);
                    return city;
                } catch (error) {
                    console.error('Error fetching city:', error);
                }
            }
            else{
                return state.city;
            }
        },
        async fetchMusse({ commit, state }) {  
            if (state.musses.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees`);
                    const musses = response.data.musees.data;
                    commit('SET_MUSSES', musses);
                    return musses;
                } catch (error) {
                    console.error('Error fetching musses:', error);
                }
            }
            else{
                return state.musses;
            }
        },
        async fetchActivity({ commit, state }) {  
            if (state.activities.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety`);
                    const activety = response.data.activety.data;
                    commit('SET_ACTIVITY', activety);
                    return activety;
                } catch (error) {
                    console.error('Error fetching musses:', error);
                }
            }
            else{
                return state.activities;
            }
        },
        async fetchEvents({ commit, state }) {  
            if (state.events.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allevent`);
                    const events = response.data.events.data;
                    commit('SET_EVENTS', events);
                    return events;
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
            else{
                return state.events;
            }
        },
        async fetchArticles({ commit, state }) {  
            if (state.articles.length === 0) {
                try {
                    const response = await axios.get(`https://blog.tunisiagotravel.com/wp-json/wp/v2/posts`);
                    const articles = response.data;
                    commit('SET_ARTICLES', articles);
                    return articles;
                } catch (error) {
                    console.error('Error fetching articles:', error);
                }
            }
            else{
                return state.articles;
            }
        },
        async fetchHotelsByDestinationId({ commit }, destination_id) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allhotels?destination_id=${destination_id}`);
              const data = response.data.hotels;
              let filteredList=data.filter(x=>x.destination._id===destination_id);
              commit('SET_HOTELS_DESTINATIONS', filteredList); 
              return filteredList;
            } catch (error) {
              console.error('Error fetching hotels:', error);
              return [];
            }
          },
          async fetchHotelsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}hotels?page=${page}`);
                const data = response.data.hotels;
                commit('SET_HOTELS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching hotels:', error);
                return [];
              }
          },
          async fetchMaisonByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}maison?page=${page}`);
                const data = response.data.maisons;
                commit('SET_MAISON_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching hotels:', error);
                return [];
              }
          },
          async fetchWeekendByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}weekend?page=${page}`);
                const data = response.data.events;
                commit('SET_WEEKEND_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchEventsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allevent?page=${page}`);
                const data = response.data.events;
                commit('SET_EVENTS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchActivityByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety?page=${page}`);
                const data = response.data.activety;
                commit('SET_ACTIVITY_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchCarByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allcars?page=${page}`);
                const data = response.data.cars;
                console.log("my cars ",data);
                commit('SET_CAR_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching cars:', error);
                return [];
              }
          },
          async fetchMusseByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees?page=${page}`);
                const data = response.data.musees;
                commit('SET_MUSSE_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching musse:', error);
                return [];
              }
          },
          async fetchRestaurantsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurants?page=${page}`);
                const data = response.data.restaurants;
                commit('SET_RESTAURANTS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching restaurants:', error);
                return [];
              }
          },
          async fetchEventById({ commit }, event_id) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}event/${event_id}`);
              const event = response.data;
              commit('SET_EVENT', event); 
              return event;
            } catch (error) {
              console.error('Error fetching event:', error);
              return null;
            }
          },
          async fetchMusseById({ commit }, musse_id) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees/${musse_id}`);
              const musse = response.data;
              console.log('my musse ',musse);
              commit('SET_MUSSE', musse); 
              return musse;
            } catch (error) {
              console.error('Error fetching musse:', error);
              return null;
            }
          },
          async fetchRestaurantsByDestinationId({ commit }, destination_id) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurants?destination_id=${destination_id}`);
              const data = response.data.restaurants.data;
              commit('SET_RESTAURANTS_DESTINATIONS', data); 
              return data;
            } catch (error) {
              console.error('Error fetching restaurants:', error);
              return [];
            }
          },
          async fetchActivitiesByDestinationId({ commit }, destination_id) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety?destination_id=${destination_id}`);
              const data = response.data.activety.data;
              let filteredList=data.filter(x=>x.destination_id===destination_id);
              commit('SET_ACTIVITIES_DESTINATIONS', filteredList); 
              return filteredList;
            } catch (error) {
              console.error('Error fetching activities:', error);
              return [];
            }
          },
          fetchSelectedHotel({ commit },hotel){
              commit("SET_SELECTED_HOTEL",hotel)
              localStorage.setItem("selectedHotel",hotel);
          },
          fetchSelectedCircuit({ commit },circuit){
            commit("SET_SELECTED_CIRCUIT",circuit)
            localStorage.setItem("selectedCircuit",circuit);
            },
          fetchSelectedRoom({ commit },room){
            commit("SET_SELECTED_ROOM",room);
            localStorage.setItem("selectedRoom",room);
         },
         fetchSelectedReservation({ commit },reservationSelected){
            commit("SET_SELECTED_RESERVATION",reservationSelected);
            localStorage.setItem("selectedReservation",reservationSelected);
         },
         fetchSelectedRestaurant({ commit },restaurantSelected){
            commit("SET_SELECTED_RESTAURANT",restaurantSelected);
            localStorage.setItem("restaurantSelected",restaurantSelected);
         },
    },
    getters:{
        getHotelSelected: (state) => state.hotelSelected? state.hotelSelected : localStorage.getItem("selectedHotel"),
        getRoomSelected:(state)=> state.roomSelected ? state.roomSelected : localStorage.getItem("selectedRoom"),
        getReservationSelected: (state)=>state.reservationSelected ? state.reservationSelected : localStorage.getItem("selectedReservation"),
        getRestaurantSelected: (state) => state.restaurantSelected ? state.restaurantSelected : localStorage.getItem("restaurantSelected"),
        getCircuitSelected: (state)=> state.circuitSelected ? state.circuitSelected : localStorage.getItem("selectedCircuit")

    }
});